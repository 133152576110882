import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import ogImage from '../../../static/discover-profile.png';
import plane from '../../img/layout/plane.svg';
import mail from '../../img/layout/mail.svg';
import headerBanner from '../../img/layout/SCRAPER-API.svg';
import logo from '../../img/logo.svg';
import './styles.scss';

export default ({ children, title, metaName, metaContent, small }) => {
	const adUrl = "https://www.scraperapi.com?_from=proxyapi";
	const alt = "scraperapi";
  return (
    <div className="page-wrap has-text-centered">
      <div
        className="w-100 position-relative"
        style={{ background: '#055cb4', cursor: 'pointer' }}
        onClick={() => window.open(adUrl, '_blank')}
      >
        <img src={headerBanner} alt={alt} className="my-auto" />
      </div>
      <section className="section px-0">
        <Helmet>
          <title>{title}</title>
          <meta name={metaName} content={metaContent} />
          <html lang="en" />
          <link rel="canonical" href={global && global.window && global.window.location.href} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={metaContent} />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:url" content={global && global.window && global.window.location.href} />
          <meta property="og:image" content={ogImage} />

          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={metaContent} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={ogImage} />
        </Helmet>
        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="Discover Profile" />
          </Link>
        </div>

        <div className={`content ${small ? 'small' : ''}`}>
          <div className="header d-none d-md-block" style={{ position: 'relative' }}>
            <img src={mail} className="mail-icon" alt="" />
            <img src={plane} className="plane-icon" alt="" />
          </div>
          {children}
        </div>
      </section>
      <footer className="footer navbar">
        <div className="row">
          <div className="col-lg-8">
            <div className="d-flex footer-right m-auto flex-wrap">
              <div className="footer-right">&copy; {new Date().getFullYear()} DiscoverProfile.com</div>
              <div className="footer-right" style={{ fontWeight: 400, marginLeft: '20px' }}>
                Looking for the most advanced{' '}
                <a href="https://get.brightdata.com/web-scrapers" className="mx-1">
                  web scraping platform?
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 footer-left">
            <Link to="/contact">Contact</Link>
            <Link to="/terms-of-use">Terms of Service</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};
